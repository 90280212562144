/* eslint-disable import/order */

// eslint-disable-next-line import/no-unresolved
import 'style/dexterity/app.scss';

/**
 * MomentJS locales we want to make available.
 * This is set based on browser of the user in the created() hook below
 *
 * @link https://github.com/moment/moment/issues/5926
 */
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/en-nz';
import 'moment/dist/locale/en-au';

// Spark
import Vue, { defineAsyncComponent, onMounted } from 'vue';
import axios from 'axios';
import './preloadImages';
import PortalVue from 'portal-vue';
import moment from 'moment';
import VueClipboad from 'vue-clipboard2';
import { VTooltip } from 'floating-vue';
import '@/utils/beans';

/* @ts-expect-error Unresolved types decs due to old package */
import VueVirtualScroller, { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import { SESSION_LIFETIME_IN_MINUTES } from './config/auth';

import Link from './components/Link';

import DextIcon, { InterimIcon } from './components/Icon';
import ActionBar from './components/ActionBar.vue';
import AlertIcon from './components/AlertIcon.vue';
import Avatar from './components/Avatar.vue';
import BaseDataBadge from './components/BaseDataBadge.vue';
import BetaBanner from './components/BetaBanner.vue';
import Card from './components/Card.vue';
import CheckBox from './components/CheckBox.vue';
import Check from './components/Check.vue';
import CheckScript from './components/CheckScript.vue';
import ClientIcon from './components/ClientIcon.vue';
import DatePicker from './components/DatePicker.vue';
import DextLogo from './components/DextLogo';
import DiscountPrompt from './components/organisms/DiscountPrompt.vue';
import DatePickerSingle from './components/DatePickerSingle.vue';
import { Grid } from './components/AgGrid';
import ErrorState from './components/ErrorState.vue';
import HelpButton from './components/HelpButton.vue';
import HealthScore from './components/HealthScore.vue';
import HoverBox from './components/HoverBox.vue';
import InfoBanner from './components/InfoBanner.vue';
import FilterOptions from './components/FilterOptions.vue';
import ListContainer from './components/ListContainer.vue';
import LoadingIcon from './components/LoadingIcon.vue';
import { PageTitle } from './components/PageTitle';
import ModalLEGACY from './components/ModalLEGACY.vue';
import NewTag from './components/NewTag.vue';
import ProgressBar from './components/ProgressBar.vue';
import ProviderLink from './components/ProviderLink.vue';
import Radio from './components/Radio.vue';
import RichTextArea from './components/RichTextArea.vue';
import SecondaryNav from './components/SecondaryNav.vue';
import Select from './components/Select.vue';
import Spinner from './components/Spinner';
import SimpleList from './components/organisms/SimpleList.vue';
import ExportToPDF from './components/ExportToPDF.vue';
import ExportToExcel from './components/ExportToExcel.vue';
import Unreconciled from './components/Unreconciled.vue';
import WarningBanner from './components/WarningBanner.vue';
import ClientListEmptyState from './components/ClientList/ClientListEmptyState.vue';
import ClientListFilter from './components/ClientList/ClientListFilter.vue';

import bootstrap from './common-bootstrap';

import xavierClientGlobalsPlugin from './plugins/xavierClientGlobals';

import validationPlugin, { setupValidation } from './plugins/validation';
import { setupToasts } from './plugins/toasts';

import settingsPlugin from './plugins/settings';

import openInNewTabDirective from './directives/openLinkInNewTab';

import visibleDirective from './directives/visible';

import store from './store';

import { getBrowserLocales } from './utils/browser';

/**
 * We are going to be using Pinia going forward.
 * Explicit import to not forget that.
 */
import { pinia } from './store/pinia';
import { i18n } from './localisation/createTranslations';

import createEventListener from './events';

import { createRouter } from './router';

import {
    ensureCorrectTeam,
    watchForInactivity,
    handleAuthErrors,
    addRefererEmbeddedHeader,
    addCsrfTokenHeader,
} from './utils/axiosInterceptors';

import { TokenRefresher } from './utils/auth';

import pluralize from 'pluralize';
import { filtersPolyfillPlugin } from './plugins/filtersPolyfill';

// eslint-disable-next-line import/no-unresolved
import 'spark-bootstrap';
/* @ts-expect-error Unresolved types decs due to old package */
import sparkMixin from 'spark'; // eslint-disable-line import/no-unresolved
import './spark-components/bootstrap';
import { useContactSupport } from './composables/useContactSupport';
import useXavierGlobals from './hooks/useXavierGlobals';
import { useVueI18n } from '@/composables/useVueI18n';

Vue.use(validationPlugin);

// Vendor components
Vue.use(VueVirtualScroller);
Vue.use(VueClipboad);
Vue.use(PortalVue);

// Our vue extensions
Vue.component('RecycleScroller', RecycleScroller);
Vue.component('VLink', Link);

Vue.component(
    'VButton',
    defineAsyncComponent(() => import('./components/buttons/Button'))
);

Vue.component('VDextIcon', DextIcon);
Vue.component('VIcon', InterimIcon);
Vue.component('VActionBar', ActionBar);
Vue.component('VAlertIcon', AlertIcon);
Vue.component('VAvatar', Avatar);

Vue.component('VBaseDataBadge', BaseDataBadge);
Vue.component('VBetaBanner', BetaBanner);
Vue.component('VCard', Card);
Vue.component('VCheckBox', CheckBox);
Vue.component('VCheck', Check);
Vue.component('VCheckScript', CheckScript);
Vue.component('VClientIcon', ClientIcon);
Vue.component('VDate', DatePicker);
Vue.component('VDextLogo', DextLogo);
Vue.component('VDiscountPrompt', DiscountPrompt);
Vue.component('VSingleDate', DatePickerSingle);
Vue.component('VGrid', Grid);
Vue.component('VErrorState', ErrorState);
Vue.component('VHelp', HelpButton);
Vue.component('VHealthScore', HealthScore);
Vue.component('VHoverBox', HoverBox);
Vue.component('VInfoBanner', InfoBanner);
Vue.component('VFilterOptions', FilterOptions);
Vue.component('VListContainer', ListContainer);
Vue.component('VLoadingIcon', LoadingIcon);
Vue.component('VPageTitle', PageTitle);
Vue.component('VModal', ModalLEGACY);
Vue.component('VNewTag', NewTag);
Vue.component('VProgressBar', ProgressBar);
Vue.component('VProviderLink', ProviderLink);
Vue.component('VRadio', Radio);
Vue.component('VRichTextArea', RichTextArea);
Vue.component('VSecondaryNav', SecondaryNav);
Vue.component('VSelect', Select);
Vue.component('VSpinner', Spinner);
Vue.component('VSimpleList', SimpleList);
Vue.component('VExportToPdf', ExportToPDF);
Vue.component('VExportToExcel', ExportToExcel);
Vue.component('VUnreconciled', Unreconciled);
Vue.component('VWarningBanner', WarningBanner);

Vue.component('VClientListEmptyState', ClientListEmptyState);
Vue.component('VClientListFilter', ClientListFilter);

pluralize.addPluralRule(/is$/i, 'are');

Vue.use(filtersPolyfillPlugin);
Vue.use(xavierClientGlobalsPlugin);
Vue.use(settingsPlugin, { store });

Vue.directive('open-in-new-tab', openInNewTabDirective);
Vue.directive('visible', visibleDirective);
Vue.directive('tooltip', VTooltip);

Vue.prototype.$plural = pluralize;

// Start application

window.Bus = new Vue();

Vue.prototype.$echo = createEventListener(store);
Vue.prototype.$bus = window.Bus;

// Add our custom fields to registration
// window.Spark.forms.register = {
//     team_location: 'GB',
// };

// Axios interceptors

if (window.Xavier?.userId) {
    const fiveMinutesMs = 5 * 60 * 1000;
    const sessionTimeoutMs = SESSION_LIFETIME_IN_MINUTES * 60 * 1000;
    const tokenRefresher = new TokenRefresher(axios);

    axios.interceptors.request.use(ensureCorrectTeam(store));
    axios.interceptors.request.use(addRefererEmbeddedHeader);
    axios.interceptors.request.use(addCsrfTokenHeader);

    axios.interceptors.response.use(
        watchForInactivity(() => {
            window.Bus.$emit('sessionEnding', { endThreshold: fiveMinutesMs });
        }, sessionTimeoutMs - fiveMinutesMs),

        handleAuthErrors(() => {
            tokenRefresher.stop(); // Session has ended so stop trying to refresh token

            if (window.self === window.top) {
                return window.Bus.$emit('sessionEnded');
            }

            if (window.top) {
                window.top.location.pathname = '/';
            }

            return;
        })
    );
}

// Bootstrap app

const router = createRouter();

/* @ts-expect-error Unexpected type */
bootstrap(Vue, router);

// Mixins
const mixins = [sparkMixin];

i18n.setup();

new Vue({
    el: '#app',
    i18n: i18n.vueI18n,
    mixins,
    pinia,
    store,
    router,
    setup() {
        const translator = useVueI18n();

        setupValidation();
        setupToasts(translator);

        const intercom = useContactSupport();
        const { userEmail, userName, userId, userHash } = useXavierGlobals();

        function bootIntercom() {
            const hasUserData = !userEmail || !userName || !userId || !userHash;

            if (hasUserData) {
                return intercom?.boot();
            }

            intercom?.boot({
                email: userEmail,
                name: userName,
                user_id: userId.toString(),
                user_hash: userHash,
            });
        }

        onMounted(() => {
            bootIntercom();
        });
    },
    created() {
        if (window.Xavier?.initialError) {
            this.$toaster.error(window.Xavier.initialError, { timeout: 10000 });
        }

        if (window.Xavier?.initialSuccess) {
            this.$toaster.success(window.Xavier.initialSuccess, { timeout: 10000 });
        }

        moment.locale(getBrowserLocales());
    },
});

// if (module.hot) module.hot.accept();
