<template>
    <DatePickerSingle v-model="currentValue" :clearable="true" placeholder="dd/mm/yyyy" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import DatePickerSingle from '@/components/DatePickerSingle.vue';

export default defineComponent({
    components: { DatePickerSingle },
    computed: {
        currentValue: {
            get() {
                return this.internalValue;
            },
            set(newValue) {
                this.internalValue = newValue;
                this.params.onDateChanged();
            },
        },
    },
    data() {
        return {
            internalValue: undefined,
        };
    },
    methods: {
        getDate() {
            return this.internalValue ? new Date(this.internalValue) : undefined;
        },
        setDate(date: never) {
            this.internalValue = date;
        },
    },
    name: 'DatePicker',
});
</script>
