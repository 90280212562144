<template>
    <div class="secondary-nav px-3">
        <div class="d-flex justify-content-between align-items-center container-fluid p-0">
            <div class="flex-1 mr-5 left">
                <slot name="left" />
            </div>

            <div :class="secondaryNavbarClassName" v-show="showNav">
                <slot />
            </div>

            <VButton
                class="secondary-navbar-toggle"
                name="Toggle Mobile Secondary Nav"
                @click.prevent.stop="toggleNav"
                v-if="!disableMobileMode && windowWidth < 1112"
            >
                <template #icon-left>
                    <VIcon decorative name="hamburger-menu-thick" size="20px" />
                </template>
                <span class="sr-only">In-page </span>
                Menu
            </VButton>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SecondaryNav',

    props: {
        disableMobileMode: {
            default: false,
            required: false,
            type: Boolean,
        },
        title: {
            default: null,
            required: false,
            type: String,
        },
    },

    data() {
        return {
            windowWidth: window.innerWidth,
            navExpanded: false,
        };
    },

    computed: {
        showNav() {
            if (this.windowWidth > 1111 || this.disableMobileMode) {
                return true;
            }

            return this.navExpanded;
        },

        secondaryNavbarClassName() {
            return {
                'secondary-navbar': true,
                'secondary-navbar-collapse': !this.disableMobileMode,
            };
        },
    },

    methods: {
        handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
        },
        toggleNav() {
            this.navExpanded = !this.navExpanded;
        },
        documentClick() {
            this.navExpanded = false;
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        document.addEventListener('click', this.documentClick);
    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleWindowResize);
    },

    destroyed() {
        document.removeEventListener('click', this.documentClick);
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.secondary-navbar-toggle {
    @include breakpoint(0, 1111px) {
        position: absolute;
        right: 7px;
        top: 0;
    }

    @include breakpoint(1112px) {
        display: none;
    }
}
</style>
