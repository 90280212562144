<template>
    <SidebarGroup name="Developer Admin">
        <template #activator>Admin</template>

        <template #icon-left>
            <VIcon decorative name="private-thick" />
        </template>

        <SidebarItem
            :key="item.name"
            :name="item.name"
            sub-item
            :href="item.href || null"
            :to="item.to || null"
            v-on="item.onClick ? { click: item.onClick } : {}"
            v-for="item in items"
        >
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';

export default {
    name: 'PrecisionAdminSidebarItems',

    components: {
        SidebarGroup,
        SidebarItem,
    },

    setup() {
        const items = [
            {
                href: '/nova',
                name: 'Nova',
                label: 'Nova',
            },
        ].filter((item) => item);

        return { items };
    },
};
</script>

<style lang="scss" scoped></style>
