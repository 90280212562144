import VToaster from 'v-toaster';
import Vue from 'vue';

import { IS_EMBEDDED } from '@/config/env.js';

function postMessageToast(translator: any, options: { body: string; type: 'error' | 'success' | 'info' | 'warning' }) {
    let toastTitle = null;

    switch (options.type) {
        case 'error':
            toastTitle = translator.t('components.toast.error');
            break;
        case 'info':
            toastTitle = translator.t('components.toast.info');
            break;
        case 'success':
            toastTitle = translator.t('components.toast.success');
            break;
        case 'warning':
            toastTitle = translator.t('components.toast.warning');
            break;
    }

    window.parent.postMessage(
        {
            action: 'SHOW_TOASTER',
            payload: {
                body: options.body,
                title: toastTitle,
                type: options.type,
            },
        },
        '*'
    );
}

export function setupToasts(translator: any) {
    if (IS_EMBEDDED) {
        Vue.prototype.$toaster = {
            error: (body: string) => postMessageToast(translator, { body, type: 'error' }),
            info: (body: string) => postMessageToast(translator, { body, type: 'info' }),
            success: (body: string) => postMessageToast(translator, { body, type: 'success' }),
            warning: (body: string) => postMessageToast(translator, { body, type: 'warning' }),
        };
    } else {
        Vue.use(VToaster, { timeout: 8000 });
    }
}
