<template>
    <VSpinner v-if="!ready" />

    <div class="shell-div" v-else>
        <RouterView :key="$route.path" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { Feature } from '@/enums/Feature';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';
import { formatCurrency } from '@/utils/currency';

const { mapActions, mapGetters } = createNamespacedHelpers('legacyClients');
const { mapActions: mapImportsActions } = createNamespacedHelpers('imports');

export default {
    name: 'ClientLayout',
    props: {
        clientId: { type: String, required: true },
    },
    provide() {
        const self = this;

        return {
            $deepLink: this.deepLink,
            $formatCurrency: (value, decimals = 2) => formatCurrency(value, this.client.currency, decimals),
            get $clientTaxName() {
                return self.client.salesTaxName;
            },
        };
    },

    setup() {
        return {
            isQboDeepLinksEnabled: useIsFeatureEnabled(Feature.QBO_DEEP_LINKS),
        };
    },

    computed: {
        ...mapGetters({ client: 'currentClient' }),
        ready() {
            return !!this.client;
        },
        importParams() {
            return {
                id: this.client?.id ?? null,
                stage: 'all',
                mode: 'all',
            };
        },
    },
    async created() {
        try {
            await this.selectClient(this.clientId);
        } catch (ex) {
            console.error(ex);

            this.$router.push({ name: 'team.home' });
            this.$toaster.error("Client doesn't exist");
        }
    },
    methods: {
        ...mapActions(['selectClient', 'syncClient']),
        ...mapImportsActions(['LOAD_CLIENT_IMPORTS', 'CREATE_IMPORT_PLACEHOLDER']),
        handleSyncClient() {
            this.syncClient(this.importParams);
            this.CREATE_IMPORT_PLACEHOLDER({
                clientId: this.clientId,
                mode: this.importParams.mode,
                stage: this.importParams.stage,
            });
        },
        deepLink(entity, id) {
            let link = this.client.providerLink;
            let urlSearchParams = new URLSearchParams();

            //for non-links in grid
            if (!id) {
                return '';
            }

            switch (entity) {
                case 'contact':
                    switch (this.client.provider) {
                        case 'quickbooks':
                            if (this.isQboDeepLinksEnabled) {
                                if (id.indexOf('customer_') === 0) {
                                    link = 'https://qbo.intuit.com/app/customerdetail?';
                                    urlSearchParams.append('nameId', id.replace('customer_', ''));
                                } else {
                                    link = 'https://qbo.intuit.com/app/vendordetail?';
                                    urlSearchParams.append('nameId', id.replace('vendor_', ''));
                                }

                                urlSearchParams.append('deeplinkcompanyid', this.client.providerId);
                                link += urlSearchParams.toString();
                            }

                            break;

                        default:
                            link = `${link}&redirecturl=/Contacts/View/${id}`;
                            break;
                    }

                    return link;
                default:
                    throw `Unknown entity type: [${entity}]`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.shell-div {
    display: contents;
}
</style>
