import axios from 'axios';
import Vue from 'vue';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { IS_EMBEDDED } from '@/config/env';
import ClientProvider from '@/enums/ClientProvider';
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientService from '@/services/Api/ClientService';
import { isFeatureEnabled } from '@/utils/features';
import { reportingModeForClient } from '@/utils/reportingModes';

import * as types from './types';

/* eslint-disable sort-keys */
export function augmentClient(client, localeManager) {
    return {
        ...client,
        ...localeManager.countryConfig(client.countryCode),
        localise: function (content) {
            return localeManager.localiseText(content, client.countryCode);
        },
        reportingModeTitle: reportingModeForClient(client, localeManager),
        isIntegrated: client.provider !== 'unintegrated',
    };
}

// eslint-disable-next-line import/no-default-export
export default function clientModule(localeManager) {
    return {
        namespaced: true,
        state() {
            return {
                currentClientId: undefined,
                clients: {},
                loading: false,
                clientListLoaded: false,
                clientUserIds: {},
                clientProviderUsers: {},
            };
        },
        getters: {
            loading: (state) => state.loading,
            clientListLoaded: (state) => state.clientListLoaded,
            currentClient: (state) => {
                return state.clients[state.currentClientId];
            },
            clientList: (state) => Object.values(state.clients),
            clientLinked: (state) => (id) => state.clients[id].linked,
            reportMode: (state, getters) =>
                getters.currentClient && getters.currentClient.reportingMode !== 'monthEnd' ? 'reporting' : 'dashboard',
            clientCanSync:
                (state, _getters, _rootState, rootGetters) =>
                (id, mode, stage = null) => {
                    const client = state.clients[id];

                    if (!client) {
                        return false;
                    }

                    if (
                        // a specific mode and stage is running
                        rootGetters['imports/isClientImporting'](id, mode, stage) ||
                        // a full sync is running
                        rootGetters['imports/isClientImporting'](id, 'all', 'all') ||
                        // Any sync is running, but we want to initiate a full one
                        (mode === 'all' && rootGetters['imports/isClientImporting'](id))
                    ) {
                        return false;
                    }

                    if (!client.isActive) {
                        /**
                         * Explicit check for bank_reconciliation is required because
                         * bank_rec requires ledger connection to recalculate
                         */
                        if ((mode === 'insight' || mode === 'dashboard') && stage === 'bank_reconciliation') {
                            return false;
                        }

                        /**
                         * Recalculation of the insight could be dashboard or insight mode, if the stage is not all
                         * it is recalculating specific insight
                         * It has to be enabled for deactivated clients
                         */
                        if ((mode === 'insight' || mode === 'dashboard') && stage !== 'all') {
                            return true;
                        }

                        return false;
                    }

                    /**
                     * We don't want unlinked clients to do any recalculations like flow, insights or cleanup
                     * because they might have outdated data. On the other hand demo clients should be able to do
                     * recalculations even if they are disconnected - this is to allow insight recalculations for
                     * different reporting periods.
                     */
                    if (mode !== 'all' && !client.linked && !client.isDemo) {
                        return false;
                    }

                    return true;
                },
            allClientsCount: (state, getters) => getters.clientList.length,
            myClientsCount: (state, getters) =>
                getters.clientList.filter(
                    (c) => c.userId === window.Xavier.userId || c.secondUserId === window.Xavier.userId
                ).length,
            favouritesCount: (state, getters) => getters.clientList.filter((c) => c.hasFlagged).length,
            filteredClientList: (state, getters) => (viewFilter, searchFilter) => {
                let filtered;

                switch (viewFilter) {
                    case 'myClients':
                        filtered = getters.clientList.filter(
                            (c) => c.userId === window.Xavier.userId || c.secondUserId === window.Xavier.userId
                        );
                        break;
                    case 'favourites':
                        filtered = getters.clientList.filter((c) => c.hasFlagged);
                        break;
                    default:
                        filtered = getters.clientList;
                }

                return searchFilter
                    ? filtered.filter(
                          (c) =>
                              c.name
                                  .toLowerCase()
                                  .concat(c.combinedTags.toLowerCase(), c.legalName.toLowerCase())
                                  .indexOf(searchFilter.toLowerCase()) !== -1
                      )
                    : filtered;
            },
            currentClientUserIds: (state) => state.clientUserIds[state.currentClientId],
            currentClientProviderUsers: (state) => state.clientProviderUsers[state.currentClientId],
            getClientById: (state) => (id) => state.clients[id],
        },
        actions: {
            async loadClientList({ commit, state }) {
                if (!state.loading) {
                    commit(types.LOADING_STARTED);

                    const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

                    const { data } = await ClientService.getAllClients(practiceCrn, {});

                    commit(types.CLIENT_LIST_LOADED, { clientList: data, completeClientData: true });
                }
            },
            async loadClientListLite({ commit }) {
                const { rbExternalId: practiceCrn } = window.Xavier.currentTeam;

                const { data } = await ClientService.getClientList(practiceCrn);

                commit(types.CLIENT_LIST_LOADED, { clientList: data, completeClientData: false });
            },
            async loadClient({ commit }, id) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.get(`/api/teams/${practiceCrn}/client/${id}?include=trackingCategories`);

                commit(types.CLIENT_LOADED, data.data);
            },
            async loadClientUserIds({ commit }, id) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.get(`/api/teams/${practiceCrn}/client/${id}/users`);

                commit(types.CLIENT_USER_LIST_LOADED, { clientId: id, userIds: data.userIds });
            },
            async loadClientProviderUsers({ commit }, id) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.get(`/api/teams/${practiceCrn}/client/${id}/provider-users`);

                commit(types.CLIENT_PROVIDER_USER_LIST_LOADED, { clientId: id, users: data.users });
            },
            async checkLinkStatus({ commit, state }, id) {
                if (state.clients[id] && !state.clients[id].isIntegrated) {
                    commit(types.SET_CLIENT_LINKED, { clientId: id, isLinked: true });

                    return;
                }

                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.get(`/api/teams/${practiceCrn}/client/${id}/link-status`);

                commit(types.SET_CLIENT_LINKED, { clientId: id, isLinked: data.isLinked });
            },
            async selectClient({ dispatch, commit, state }, id) {
                if (id !== state.currentClientId) {
                    commit(types.CLEAR_CLIENT);

                    await dispatch('sandbox/clearSandbox', null, { root: true });
                }

                await dispatch('loadClient', id);

                commit(types.CLIENT_SELECTED, id);
            },
            async syncClient({ state, commit, rootGetters }, { id, stage = null, mode, params, flowId = null }) {
                if (rootGetters['imports/isClientImporting'](id, mode, stage)) {
                    return;
                }

                // Disable sync for playground clients
                if (state.clients[id].provider === ClientProvider.PLAYGROUND) {
                    return;
                }

                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    const url = flowId
                        ? `/teams/${practiceCrn}/client/${id}/sync/${mode}/${stage || ''}/${flowId}`
                        : `/teams/${practiceCrn}/client/${id}/sync/${mode}/${stage || ''}`;

                    const { data } = await axios.post(url, params);

                    if (data.redirectRequired === false) {
                        commit(types.CLIENT_LOADED, data.client.data);
                    } else {
                        if (IS_EMBEDDED) {
                            dispatchIframeEvent({
                                action: 'NAVIGATE',
                                payload: {
                                    pathName: 'CLIENT_CONNECTION_SETTINGS',
                                    practiceCrn: practiceCrn.toString(),
                                    clientCrn: state.clients[id].rbExternalId.toString(),
                                },
                            });
                        } else {
                            window.location = data.redirectUrl;
                        }
                    }
                } catch (ex) {
                    console.error(ex);
                }
            },
            async flagClient({ commit }, client) {
                try {
                    commit(types.CLIENT_LOADED, client);

                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    await axios.post(`/api/teams/${practiceCrn}/client/${client.id}/flag`);
                } catch (ex) {
                    commit(types.CLIENT_LOADED, { id: client.id, hasFlagged: !client.hasFlagged });
                    throw ex;
                }
            },
            async clearReportingDate({ commit }, client) {
                /* eslint-disable no-useless-catch */
                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    await axios.delete(`/api/teams/${practiceCrn}/client/${client.id}/reporting-date`);

                    commit(types.CLEAR_REPORTING_DATE, client.id);
                } catch (ex) {
                    throw ex;
                }
                /* eslint-enable no-useless-catch */
            },
            async updateIsIncludingDuplicateBankTransactions({ commit }, { id, value }) {
                /* eslint-disable no-useless-catch */
                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    await ClientService.postIsIncludingDuplicateBankTransactions(practiceCrn, id, value);

                    commit(types.DUPLICATE_BANK_TRANSACTIONS, { id, value });
                } catch (ex) {
                    throw ex;
                }
                /* eslint-disable no-useless-catch */
            },
            async addTag({ commit }, { id, tag }) {
                /* eslint-disable no-useless-catch */
                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                    const { data } = await axios.post(`/api/teams/${practiceCrn}/client/${id}/tags`, tag);

                    commit(types.CLIENT_LOADED, { id: id, tags: data.data });
                } catch (ex) {
                    throw ex;
                }
                /* eslint-enable no-useless-catch */
            },
            async removeTag({ commit }, { id, tagId }) {
                /* eslint-disable no-useless-catch */
                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                    const { data } = await axios.delete(`/api/teams/${practiceCrn}/client/${id}/tags/${tagId}`);

                    commit(types.CLIENT_LOADED, { id: id, tags: data.data });
                } catch (ex) {
                    throw ex;
                }
                /* eslint-enable no-useless-catch */
            },
            async deleteClient({ commit, dispatch }, id) {
                commit(types.REMOVE_CLIENT, id);

                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    await axios.delete(`/api/teams/${practiceCrn}/client/${id}`);
                } catch (ex) {
                    await dispatch('loadClientList');

                    throw ex;
                }
            },
            async unlinkClient({ commit, dispatch }, id) {
                commit(types.SET_CLIENT_LINKED, { clientId: id, isLinked: false });

                try {
                    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                    await ClientService.unlink(practiceCrn, id);
                    await dispatch('loadClient', id);
                } catch (ex) {
                    commit(types.SET_CLIENT_LINKED, { clientId: id, isLinked: true });

                    console.error(ex);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async restoreClient({ commit, dispatch }, slug) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;

                const { data } = await axios.post(`/api/teams/${practiceCrn}/client/${slug}/restore`);

                return data;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async saveComment({ dispatch, commit, state }, details) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                await axios.post(
                    `/api/teams/${practiceCrn}/client/${details.id}/comments`,
                    details.comment
                );
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async deleteComment({ dispatch, commit, state }, details) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                await axios.delete(
                    `/api/teams/${practiceCrn}/client/${details.id}/comment/${details.comment.id}`
                );
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            async getClientCentralisedClientListUrl({ state }, { rbInternalId }) {
                const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
                const { data } = await axios.get(
                    `/api/teams/${practiceCrn}/client/${rbInternalId}/centralized-client-business`
                );

                return data.url;
            },
        },
        mutations: {
            [types.LOADING_STARTED](state) {
                state.loading = true;
            },
            [types.CLIENT_LIST_LOADED](state, { clientList, completeClientData }) {
                for (const client of clientList) {
                    Vue.set(state.clients, client.id, {
                        ...state.clients[client.id],
                        ...augmentClient(client, localeManager),
                    });
                }

                for (const id of Object.keys(state.clients)) {
                    if (clientList.every((c) => c.id != id)) {
                        Vue.delete(state.clients, id);
                    }
                }

                if (completeClientData) {
                    state.clientListLoaded = true;
                    state.loading = false;
                } else {
                    state.clientListLoaded = false;
                }
            },
            [types.CLIENT_LOADED](state, client) {
                Vue.set(state.clients, client.id, {
                    ...state.clients[client.id],
                    ...augmentClient(client, localeManager),
                });
            },
            [types.CLIENT_USER_LIST_LOADED](state, { clientId, userIds }) {
                Vue.set(state.clientUserIds, clientId, userIds);
            },
            [types.CLIENT_PROVIDER_USER_LIST_LOADED](state, { clientId, users }) {
                Vue.set(state.clientProviderUsers, clientId, users);
            },
            [types.REMOVE_CLIENT](state, id) {
                Vue.delete(state.clients, id);
            },
            [types.CLIENT_SELECTED](state, id) {
                state.currentClientId = id;
            },
            [types.SET_CLIENT_LINKED](state, { clientId, isLinked }) {
                state.clients[clientId].linked = isLinked;
            },
            [types.CLEAR_CLIENT](state) {
                state.currentClientId = null;
            },
            [types.CLEAR_REPORTING_DATE](state, id) {
                state.clients[id].reportingMode = 'monthEnd';
                // eslint-disable-next-line no-undef
                state.clients[id].reportingStart = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                // eslint-disable-next-line no-undef
                state.clients[id].reportingEnd = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            },
            [types.DUPLICATE_BANK_TRANSACTIONS](state, { id, value }) {
                state.clients[id].isIncludingDuplicateBankTransactions = value;
            },
        },
    };
}
