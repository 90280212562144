<template>
    <BaseNotification>
        <div slot="content">
            <strong v-if="!completed" class="notification-title">
                <span>
                    <VIcon name="sync" spin />
                </span>
                {{ translator.t(t.inProgress) }}
            </strong>
            <strong v-else class="notification-title text-success">
                <span><VIcon name="checklist-thick" /></span>
                {{ completedFlows }} {{ $plural('Flow', totalFlows) }} Created Successfully!
            </strong>
            <div v-if="!completed" class="notification-content">
                <p>{{ completedFlows }} / {{ totalFlows }} {{ translator.t(t.created) }}</p>
            </div>
        </div>
    </BaseNotification>
</template>

<script lang="ts">
import { useVueI18n } from '@/composables/useVueI18n';

import BaseNotification from './BaseNotification.vue';
import { translations as t } from './FlowCreation.translations';

export default {
    components: {
        BaseNotification,
    },
    created() {
        window.Bus.$on('flowCreationProgress', ({ batchId }) => this.setProgress(batchId));
        window.Bus.$on('flowCreationCompleted', ({ batchId }) => this.setCompleted(batchId));
        window.Bus.$on('flowCreationFailed', ({ batchId }) => this.setFailed(batchId));
    },
    data() {
        return {
            completed: false,
            completedFlows: 0,
        };
    },
    methods: {
        setCompleted(batchId) {
            if (this.batchId === batchId) {
                this.completed = true;
                setTimeout(() => this.$emit('completed'), 4000);
            }
        },
        setFailed(batchId) {
            if (this.batchId === batchId) {
                this.$emit('completed');
            }
        },
        setProgress(batchId) {
            if (this.batchId === batchId) {
                this.completedFlows++;
            }
        },
    },
    name: 'FlowCreation',
    props: {
        batchId: { required: true, type: String },
        notificationId: { required: true, type: Symbol },
        totalFlows: { required: true, type: Number },
    },
    setup() {
        const translator = useVueI18n();

        return {
            t,
            translator,
        };
    },
};
</script>

<style scoped>
.notification-title {
    font-weight: 700;
    font-size: 16px;
    /*color: #000002;*/
    margin: 0;
}

.notification-content {
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0;
    color: #606266;
    text-align: justify;
}

.notification-content p {
    margin: 0;
}
</style>
